import React, { useEffect } from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

  

const Patterns = ({ data, location }) => {
    var artists=data.allMysqlHamabeadsEn.edges
    console.log(artists);
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title="Contact us" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">Patterns</h1>
        <section itemProp="articleBody">
        <div class="row">
        {artists.map(node => (
            <div className="hamaitem col-md-4 col-sm-4 col-xs-12" key={node.artist.id}>
                <div className="hamaitembg">
                  <Link to={`/${node.artist.slug}/`}>
                        <div class="imgcaja">
                            <img className="hamabeads2" title={`${node.artist.title} hama beads`} src={`/imgs/${node.artist.slug}-hama-beads.jpg`} alt={`${node.artist.title} Hama Beads Template`} />
                        </div>
                        <div class="textocaja">
                            <h3>hama beads {node.artist.title}</h3>
                        </div>
                        <div class="footercaja"><span>Download pattern</span></div>
                        </Link>
                </div>
            </div>
        ))}
        </div>
        </section>
    </article>
    </Layout>
  )
}

export default Patterns

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMysqlHamabeadsEn (sort: { fields: [title], order: ASC }) {
      edges {
        artist: node {
          id
          title
          slug
        }
      }
    }
  }
`
